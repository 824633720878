import { useNavigate } from 'react-router-dom';
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

import { Header, Paragraph, Doctor } from './Page404.styled'

import navbarBackground from '../../../assets/img/background_purple.jpg';
import doctor from "../../../assets/img/doctor.png";
import { Grid } from "@mui/material";
import { GeneralLink, generateLink } from "../../../utils";
import { useAuthStore } from "../../../store/hooks";

export const Page404 = observer(() => {
  const {isSessionExpired} = useAuthStore();
  const navigate = useNavigate();
  const [seconds, setSeconds] = useState(3)

  useEffect(() => {
    if (isSessionExpired) {
      navigate(generateLink(GeneralLink.HOME))
    }
  }, [isSessionExpired, navigate])

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((prev: number) => {
        if (prev > 0) {
          return prev - 1;
        } else {
          return 0;
        }
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (seconds === 0) {
      navigate(generateLink(GeneralLink.HOME))
    }
  }, [seconds, navigate]);

  return (
    <Grid container height={'100vh'} display={'flex'}
          sx={{
            backgroundImage: `url(${navbarBackground})`,
            backgroundSize: 'auto 100%',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'right top'
          }}>
      <Grid item xs={12} md={6} position={'relative'} height={'100%'}>
        <Doctor src={doctor}/>
      </Grid>
      <Grid item xs={12} md={6} height={'100%'} alignItems={'center'} display={'flex'}
            flexDirection={'column'} justifyContent={'center'}>
        <Header>Błąd 404</Header>
        <Paragraph>Niestety podany adres strony
          nie jest dostępny.</Paragraph>
        <Paragraph>Przejdź na stronę <a href="/login">logowania.</a></Paragraph>
      </Grid>
    </Grid>
  )
});
