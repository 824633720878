import React from 'react';
import { RouteObject } from "react-router-dom";
import { Page404 } from '../../../../pages/page404/Page404';
import RegisterViewingUser from './../../../../pages/auth/register/registerViewingUser/RegisterViewingUser';
import RegisterPublishingUser from './../../../../pages/auth/register/registerPublishUser/RegisterPublishingUser';
import RetrievePassword from './../../../../pages/auth/retrievePassword/RetrievePassword';
import { GeneralLink } from "../../../../../utils";
import Login from '../../../../pages/auth/login/Login';
import ChangePassword from "../../../../pages/auth/changePassword/ChangePassword";
import RegisterAdminUser from "../../../../pages/auth/register/registerAdmin/RegisterAdminUser";

export const generalLinks: RouteObject[] = [
  {
    path: GeneralLink.LOGIN,
    element: <Login/>
  },
  {
    path: GeneralLink.REGISTER_VIEWER,
    element: <RegisterViewingUser/>
  },
  {
    path: GeneralLink.REGISTER_PUBLISH,
    element: <RegisterPublishingUser/>
  },
  {
    path: GeneralLink.REGISTER_ADMIN,
    element: <RegisterAdminUser/>
  },
  {
    path: GeneralLink.RETRIEVE_PASSWORD,
    element: <RetrievePassword/>
  },
  {
    path: GeneralLink.CHANGE_PASSWORD,
    element: <ChangePassword/>
  },
  {
    path: '*',
    element: <Page404/>
  },
]

const onlyGuestLinks = [
  {
    path: GeneralLink.HOME,
    element: <Login/>
  }
]

export const guestLinksExtends = generalLinks.concat(onlyGuestLinks);